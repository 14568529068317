export interface AlgoliaSearchKeys {
  appId: string;
  apiKey: string;
  index: string;
}

export const evolvingCognitionPublishedQuizzes: AlgoliaSearchKeys = {
  appId: 'RXOOULUS3M',
  apiKey: 'f29ae171a4b9a9bf823d33b45b3e4e95',
  index: 'dev_PUBLISHED_QUIZZES'
};

export const evolvingCognitionSubjects: AlgoliaSearchKeys = {
  appId: 'RXOOULUS3M',
  apiKey: '6aeda1f38c6f9a791c3525cec16f0bf2',
  index: 'dev_SUBJECTS'
};
