// Replace with your publishable key
// https://dashboard.stripe.com/apikeys

export const STRIPE_PUBLISHABLE_KEY_EC =
  'pk_test_51OmnbEDVIdTNS4rxS5L45XsSpQeWXdxbGiPyHnatPkCQwuTTnqjb2kZl0IjdwPNEwihwv1EzmeyGw7Ti5b0E7Y03002bgKphmQ';

export const STRIPE_SECRET_KEY =
  'sk_test_51OmnbEDVIdTNS4rxeDUQzfWoIDyDa7RIwH0uuv1MNJ9TfyBmRp5uGTv3qfcUOvxUwa24U4UfftQBVLB0RNR0uPx700LdzcnD06';

/*
firebase use gigasoft-prd
firebase functions:config:set stripe.version="pk_live_51K3AYiI79B00MVDmulhHAMYtMaU2OsXmXD9VI5QNpynuqXBe76kd9Sukfkz6oJIEF7Kw1lU01HM8rZ6esIrvVoCN002MkvhOIa"
 */
// export const stripePublishableKeyProd =
//     'pk_live_51K3AYiI79B00MVDmulhHAMYtMaU2OsXmXD9VI5QNpynuqXBe76kd9Sukfkz6oJIEF7Kw1lU01HM8rZ6esIrvVoCN002MkvhOIa';

/*
firebase use gigasoft-dev
firebase functions:config:set stripe.version="pk_test_51K3AYiI79B00MVDmxjNlsBrU7PO997VglZi9zQdwlXypkrLkFWFkkPC38agbgUUuChNzkxnnQCqjxtahJ25hB3VQ00i7kIB3IW"
 */
// export const stripePublishableKeyDev =
//     'pk_test_51K3AYiI79B00MVDmxjNlsBrU7PO997VglZi9zQdwlXypkrLkFWFkkPC38agbgUUuChNzkxnnQCqjxtahJ25hB3VQ00i7kIB3IW';

/*
firebase use gigasoft-prd
firebase functions:config:set stripe.secret="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"
 */
// export const stripeSecretKeyProd =
//     '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';

/*
firebase use gigasoft-dev
firebase functions:config:set stripe.secret="sk_test_51K3AYiI79B00MVDmorpWLxxGDMWVrSY4VEzVcUupUBTX3EDJMbfbrk7MpHuN9puXfnmWJBi0lOSzpHyXiTnOBOvY00BVjUEHK1"
 */
// export const stripeSecretKeyDev =
//     'sk_test_51K3AYiI79B00MVDmorpWLxxGDMWVrSY4VEzVcUupUBTX3EDJMbfbrk7MpHuN9puXfnmWJBi0lOSzpHyXiTnOBOvY00BVjUEHK1';
//
// export const stripeResctrictedProd =
//     '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';
//
// export const stripeResctrictedDev =
//     'rk_test_51K3AYiI79B00MVDmqNzAWSxOPGyQ5RG8YhxFble4XwoPtAxKLvmOAW7oBAmIIf7SjDcjdFPui5ytcVJnVENGbZHu00c4fFOJfs';
