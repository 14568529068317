import {
  devAuthURLs_domain,
  EvolutionCognitionEnvironment,
  remoteConfigParamsDev
} from '@gigasoftware/evolving-cognition/domain';
import {
  addDatabasePaths,
  addRemoteConfigParams,
  createDefaultFirebaseConfig,
  ENV_SERVER_ENUM
} from '@ngpat/firebase';
import {
  evolvingCognitionPublishedQuizzes,
  evolvingCognitionSubjects,
  firebaseEvolvingCognitionApp,
  STRIPE_PUBLISHABLE_KEY_EC
} from '@secrets';

const firebaseConfigParams = createDefaultFirebaseConfig({
  firebase: firebaseEvolvingCognitionApp,
  env: ENV_SERVER_ENUM.DEV,
  remoteConfigParams: addRemoteConfigParams(10000, 30000),
  databasePaths: addDatabasePaths(),
  appNameInFirebaseConfig: false,
  appName: 'ec-app'
});

export const environment: EvolutionCognitionEnvironment = {
  env: ENV_SERVER_ENUM.DEV,
  local: false,
  production: true,
  environment: 'development',
  isIosOrAndroid: false,
  firebaseConfigParams: firebaseConfigParams,
  algoliaPublishedQuizzes: evolvingCognitionPublishedQuizzes,
  algoliaSubjects: evolvingCognitionSubjects,
  stripePublishableKey: STRIPE_PUBLISHABLE_KEY_EC,
  authURLs: devAuthURLs_domain,
  deviceBuild: {
    isMobileApp: true,
    marketingSite: 'https://dev.evolving-cognition.com',
    marketingSiteSubscribe: 'https://dev.evolving-cognition.com',
    authDisplay: '',
    authHD: ''
  },
  ...remoteConfigParamsDev
};
